import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import './App.css';

import {Header, Footer} from 'rups-components';

import Game from './utils/Game';

import GameContext from './contexts/GameContext';

import Actions from './components/Actions';


const App = () => {
  const buttonsRef = useRef([]);
  const [game, setGame] = useState(null);
  const [message, setMessage] = useState('test');
  
  useEffect(() => {
    const newGame = new Game();
    setGame(newGame);
  }, []);

  // have the game decide what caterpillar to start with. 
  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key } = event;
      const currentFocus = document.activeElement;
      const buttons = buttonsRef.current;
      const currentIndex = buttons.indexOf(currentFocus);

      if (key === 'ArrowRight') {
        const nextButton = buttons[currentIndex + 1] || buttons[0];
        nextButton.focus();
      } else if (key === 'ArrowLeft') {
        const prevButton = buttons[currentIndex - 1] || buttons[buttons.length - 1];
        prevButton.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (game && game.state && game.state.message) {
      console.log('Game:', game.state.message);
      setMessage(game.state.message);
    }
  }, [game?.state]);
 
  if(!game) return null;  
  // each caterpillar has a name, health, and image.
  // the player can level up from a caterpillar to a butterfly
  // Game = new Game();
  // Game.state.setCharacters();

  return (
    <GameContext.Provider value={game}>
      <Header title={"rupsdemo.nl"}></Header>
      <Box
        sx={{
          bgcolor: green[50],
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2
        }}
      >
        <Typography variant="h4" component="h1" color={green[900]}>
          Caterpillar Adventure, (WIP)
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box>
              <img width={200} src={game?.state.caterpillar.image} alt="Caterpillar" />
              <Typography>Health: {game?.state.caterpillar.health}</Typography>
            </Box>
            <Box>
              <img width={200} src={game?.state.bug.image} alt="Caterpillar" />
              <Typography>Opponent Health: {game?.state.bug.health}</Typography>
            </Box>
          </Box>
        </Box>
        <Actions game={game} />
        <Typography>{message}</Typography>
      </Box>
      <Footer button={{"https://rups.cloud": "Return to rups.cloud"}}></Footer>
    </GameContext.Provider>
  );
};

export default App;