import caterpillarImage1 from '../images/rups1.jpg';
import caterpillarImage2 from '../images/rups2.jpg';
import butterflyImage from '../images/godmode.jpg';

export const caterPillars = [
    {
      name: 'Caterpillar',
      health: 20,
      image: caterpillarImage1,
      attacks: [
        {
          name: 'Bite',
          damage: 5
        },
        {
          name: 'Scratch',
          damage: 3
        }
      ]
    },
    {
      name: 'Cocoon',
      health: 200,
      image: caterpillarImage2,
      attacks: [
        {
          name: 'Bite',
          damage: 1
        },
        {
          name: 'Free',
          damage: 1
        }
      ]
    },
    {
      name: 'Butterfly',
      health: 420,
      image: butterflyImage,
      attacks: [
        {
          name: 'Bite',
          damage: 5
        },
        {
          name: 'Scratch',
          damage: 3
        }
      ]
    }
  ];