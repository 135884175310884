import { bugs } from './bugs.js';
/**
 * Represents a bug.
 * The opponent character that the player fights.
 * @class
 */

// Path: src/utils/Bug.js
class Bug {
    constructor(level) {
        const bug = bugs[level];
        console.log('bug:', bug)
        this.name = bug.name;
        this.health = bug.health;
        this.originalHealth = bug.health;
        this.image = bug.image;
        this.damageModifier = bug.damageModifier ?? 1;
    }

    reset() {
        this.health = this.originalHealth;
        this.damageModifier = 1;
    }

    attack() {
        // const newOpponentHealth = Game.opponentHealth - damage;
        // setOpponentHealth(newOpponentHealth);
        // const newMessage = `bug used ${damage} damage!`;
        // setMessage(newMessage);

        // // Check if opponent is defeated
        // if (newOpponentHealth <= 0) {
        //     setMessage('Opponent defeated! bug wins!');
        // } else {
        //     // Opponent attacks back
        //     const opponentDamage = Math.floor(Math.random() * 10) + 1;
        //     const newbugHealth = bugHealth - opponentDamage;
        //     setbugHealth(newbugHealth);
        //     const newOpponentMessage = `Bug attacked with ${opponentDamage} damage!`;
        //     setMessage(newOpponentMessage);

        //     // Check if bug is defeated
        //     if (newbugHealth <= 0) {
        //         setMessage('bug fainted! Game over.');
        //     }
        // }

        return Math.floor(Math.random() * 10) + 1 * this.damageModifier;
    }

    takeDamage(damage) {
        this.health -= damage;
    }

    isDefeated() {
        return this.health <= 0;
    }

    levelUp() {
        this.damageModifier += 1;
    }
}

export default Bug;