// actions component
// Path: src/components/Actions.js

import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import GameContext from '../contexts/GameContext';

const Actions = () => {
    const game = useContext(GameContext);

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1
            }}
        >
            {game?.state.caterpillar.attacks && game?.state.caterpillar.attacks.map((attack) => (
                <Button key={attack.name} variant="contained" color="success" onClick={() => game.attack(attack)}>{attack.name}</Button>
            ))}
        </Box>
    );
}

export default Actions;