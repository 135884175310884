import React from 'react';
import Caterpillar from './Caterpillar';
import Bug from './Bug';

/**
 * Represents the Game and maintains game state through localstorage.
 * @class
 */
// this will be like a class or an object or something. To be honest, I'm not sure what the best way to do this is. I'm just trying to get the idea across.
// this will represent the game state and will be used to update the game state and send it to the client
class Game extends React.Component {
    // maintain health of characters. 
    // maintain the state of the game
    constructor(props) {
        super(props);


        const caterpillar = this.setCaterpillar(0);
        const bug = this.setBug(0);


        this.state = {
            messages: [],
            caterpillar: caterpillar,
            bug: bug,
            level: 0,
            bugLevel: 0,
            message: '',
            messages: [],
        };

        // load the game state
        // this.loadState();

        // set the caterpillar


    }

    attack(attack) {
        console.log('attack', attack);
        this.addMessage(`Caterpillar used ${attack.name} and did ${attack.damage} damage!`);

        // attack the bug
        this.state.bug.health -= attack.damage;
        console.log(this.state)
        
    }

    addMessage = (message) => {
        this.state.messages.push(message);
        this.state.message = message;
    }

    // load game state
    loadState = () => {
        // load the game state from local storage
        // if there is no game state, create a new game state
        const gameState = JSON.parse(localStorage.getItem('gameState'));
        if (gameState) {
            this.setState(gameState);
        } else {
            this.setState(this.newState());
        }
    }

    // save game state
    saveState = () => {
        // save the game state to local storage
        localStorage.setItem('gameState', JSON.stringify(this.state));
    }

    // create a new game state
    newState() {
        // create a new game state
        // set the level to 0
        // set the character to the first caterpillar
        // set the opponent to the first bug
        // this.reset();
        this.state.level = 0;

        return this;
    }

    setCaterpillar(level) {
        return new Caterpillar(level);
    }

    setBug(level) {
        return new Bug(level);
    }
}

export default Game;
