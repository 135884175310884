import { caterPillars } from './caterpillars';

/**
 * Represents a caterpillar.
 * The hero character that the player controls.
 * @class
 */
class Caterpillar {
    constructor(level) {
        const caterpillar = caterPillars[level];
        console.log(level, caterpillar  )
        this.name = caterpillar.name;
        this.health = caterpillar.health;
        this.originalHealth = caterpillar.health;
        this.image = caterpillar.image;
        this.damageModifier = caterpillar.damageModifier ?? 1;
        this.attacks = caterpillar.attacks;
    }

    reset() {
        this.health = this.originalHealth;
        this.damageModifier = 1;
    }


    takeDamage(damage) {
        this.health -= damage;
    }

    isDefeated() {
        return this.health <= 0;
    }

    levelUp() {
        this.damageModifier += 1;
    }

    
}

export default Caterpillar; 